﻿.input-range-primary {
    .input-range__slider {
        background: #2780E3;
        border: 1px solid #2780E3;
    }

    .input-range__slider:focus {
        box-shadow: 0 0 0 5px #2780E3;
    }

    .input-range__track--active {
        background: #2780E3;
    }
}

.input-range__slider {
    appearance: none;
    background: #e9ecef;
    border: 1px solid #e9ecef;
    border-radius: 100%;
    cursor: pointer;
    display: block;
    height: 1rem;
    margin-left: -0.5rem;
    margin-top: -0.65rem;
    outline: none;
    position: absolute;
    top: 50%;
    width: 1rem;
}

.input-range__slider:active {
    transform: scale(1.3);
}

.input-range__slider:focus {
    box-shadow: 0 0 0 5px #e9ecef;
}

.input-range--disabled .input-range__slider {
    background: #cccccc;
    border: 1px solid #cccccc;
    box-shadow: none;
    transform: none;
}

.input-range__slider-container {
}

.input-range__label {
    font-size: 0.8rem;
    transform: translateZ(0);
    white-space: nowrap;
}

.input-range__label--min,
.input-range__label--max {
    bottom: -1.4rem;
    position: absolute;
}

.input-range__label--min {
    left: 0;
}

.input-range__label--max {
    right: 0;
}

.input-range__label--value {
    position: absolute;
    top: -1.8rem;
}

.input-range__label-container {
    left: -50%;
    position: relative;
}

.input-range__label--max .input-range__label-container {
    left: 50%;
}

.input-range__track {
    background: #e9ecef;
    border-radius: 0.3rem;
    cursor: pointer;
    display: block;
    height: 0.3rem;
    position: relative;
}

.input-range--disabled .input-range__track {
    background: #e9ecef;
}

.input-range__track--background {
    left: 0;
    margin-top: -0.15rem;
    position: absolute;
    right: 0;
    top: 50%;
}

.input-range__track--active {
    background: #e9ecef;
}

.input-range {
    height: 1rem;
    position: relative;
    width: 100%;
}

.input-range-hide-max .input-range__label--max {
    display: none;
}

.input-range-hide-min .input-range__label--min {
    display: none;
}

.input-range-hide-high .input-range__slider-container:nth-child(3) {
    display: none;
}

.input-range-hide-track-active .input-range__track--active {
    background: #e9ecef;
}


/*# sourceMappingURL=input-range.css.map */
