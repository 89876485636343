﻿$color-marker: #e9ecef !default;
$color-marker-active: #2780E3 !default;
$color-marker-complete: #2780E3 !default;
$color-marker-hover: #1967be !default;

$color-path: #e9ecef !default;
$color-path-complete: #2780E3 !default;

$color-text: #333 !default;
$color-marker-text: #fff !default;

@import "~progress-tracker/src/styles/progress-tracker.scss";

.progress-tracker {
    margin: initial;
}

.progress-marker::before {
    z-index: 1;
}

.progress-marker::after {
    z-index: 0;
}

.progress-step.is-disabled:not(.is-complete):not(.is-active):hover .progress-marker::before {
    background-color: $color-marker;
}

.progress-step.is-disabled.is-complete .progress-marker::before {
    background-color: $color-marker-complete;
}

.progress-step.is-disabled.is-active .progress-marker::before {
    background-color: $color-marker-active;
}
