@import "./scss/ProgressTracker.scss";
@import "./scss/InputRange.scss";
@import "~react-image-crop/lib/ReactCrop.scss";
@import "~react-datepicker/dist/react-datepicker.css";

*:focus,
button:focus {
    outline: none;
}

abbr[title] {
    text-decoration: underline solid rgba(0,0,0,0.125);
}

.text-hyphens {
    hyphens: auto;
}

.cursor-pointer {
    cursor: pointer;
}

.cursor-default {
    cursor: default !important;
}

.shadow,
.drag .drag-shadow,
.hover-shadow:hover {
    -webkit-box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
    box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
}

.card-column > .card:not(:last-child),
.card-columns > .card:not(:last-child) {
    margin-bottom: 1.25rem;
}

.accordion > .card {
    margin-bottom: unset;
}

html {
    height: 100%;
}

body {
    background-color: rgba(0, 0, 0, 0.03);
    overflow-y: scroll;
    user-select: none;
    height: 100%;
}

#root {
    background-color: #f7f7f7;
    padding-bottom: 2em;
    height: 100%;
    display: flex;
    flex-direction: column;
}

ul {
    list-style-type: square;
    padding-left: 1em;
}

.text-del {
    text-decoration: line-through;
}

.text-gold {
    color: rgba(255,185,0,1)
}

.breadcrumb {
    background-color: unset;
}

ol.breadcrumb {
    padding: 0.75rem 0px;
}

ol.list-bracket {
    counter-reset: item;
    list-style: none;
}

ol.list-bracket > li {
    position: relative;
    list-style: none;
    margin-left: 0;
    padding-left: 0;
    counter-increment: item;
}

ol.list-bracket > li::before {
    position: absolute;
    left: -2em;
    content: "[" counter(item) "]";
}

.btn:focus,
.btn:active {
    box-shadow: none !important;
}

.card > .btn-toolbar,
.card > .collapse > .btn-toolbar,
.card > .collapsing > .btn-toolbar,
.card-body > .btn-toolbar,
.card-body > .collapse > .btn-toolbar,
.card-body > .collapsing > .btn-toolbar {
    margin-left: -20px;
    margin-right: -20px;
    padding-left: calc(20px - 0.75rem);
    padding-right: calc(20px - 0.75rem);
}

.btn-float {
    float: right;
    font-size: 1.40625rem;
    font-weight: 700;
    line-height: 1;
    border: 0px;
}

.alert .form-control, .alert .form-control:focus,
.btn-input,
.card .custom-file-input, .card .custom-file-input:focus,
.card .custom-file-label, .card .custom-file-input:focus ~ .custom-file-label,
.card .custom-select, .card .custom-select:focus,
.card .form-control, .card .form-control:focus,
.dropdown-menu .form-control, .dropdown-menu .form-control:focus,
.hover-input:hover,
.input-group-append .btn-input,
.input-group-prepend .btn-input,
.input-group-text,
.modal .custom-file-input, .modal .custom-file-input:focus,
.modal .custom-file-label, .modal .custom-file-input:focus ~ .custom-file-label,
.modal .custom-select, .modal .custom-select:focus,
.modal .form-control, .modal .form-control:focus,
.navbar .form-control, .navbar .form-control:focus,
.popover-body .form-control, .popover-body .form-control:focus,
.table .custom-select, .table .custom-select:focus,
.table .form-control, .table .form-control:focus,
.bg-input {
    background-color: #e9ecef;
    border: 0px;
    box-shadow: none;
}

.btn-input.disabled,
.btn-input:disabled {
    opacity: 1;
    color: #868e96;
}

.hover-input:not(:hover) {
    background-color: transparent;
}

.navbar .form-control {
    margin: 0.5rem 1rem;
}

.form-control:disabled,
.form-control[readonly] {
    color: #868e96;
}

.modal select option {
    background-color: #fff;
}

.modal .form-control-transparent {
    background-color: transparent;
    color: inherit;
    border: 0px;
}

.modal .form-control-transparent:hover,
.modal .form-control-transparent:focus {
    background-color: #e9ecef;
    color: inherit;
}

.modal-header {
    flex-direction: column;
}

.table-flush,
.modal-header-tabs {
    margin-left: -15px;
    margin-right: -15px;
    width: calc(100% + 30px)
}

.modal-body > .table-flush:first-child {
    margin-top: -1rem;
}

.modal-body > .table-flush:first-child thead th {
    border-top: 0px;
}

.dropdown-menu .form-group {
    padding: 0.5rem 1.5rem;
}

.dropdown-menu > .dropdown-divider:first-child,
.dropdown-divider + .dropdown-divider,
.dropdown-menu > .dropdown-divider:last-child {
    display: none !important;
}

.dropdown-menu {
    z-index: 1030;
}

.dropdown-header {
    padding: 0.5rem 15px;
}

.dropdown-item {
    border-right: 3px solid transparent;
}

.dropdown-item:hover,
.dropdown-item:active {
    color: #212529;
    background-color: #f8f9fa;
}

.dropdown-item {
    border-left: 3px solid transparent;
    padding: 0.25rem 15px;
}

.dropdown-item.active {
    color: #212529;
    background-color: #f8f9fa;
    border-left: 3px #2780E3 solid;
}

.dropdown-item.no-hover:hover {
    background-color: transparent;
}

.btn.toggle-left {
    border-left: 3px transparent solid;
}

.btn.toggle-left.active {
    border-left: 3px #2780E3 solid;
}


.hide-not-first-child:not(:first-child) {
    display: none;
}

.hover-container .hover-visible {
    //visibility: hidden;
    opacity: 0.0625;
}

.hover-container:hover .hover-visible {
    //visibility: visible;
    opacity: unset;
}

.hover-container .hover-hidden {
    //visibility: visible;
    opacity: unset;
}

.hover-container:hover .hover-hidden {
    //visibility: hidden;
    opacity: 0.0625;
}

.hover-container .hover-caret-visible::after {
    //visibility: hidden;
    opacity: 0.0625;
}

.hover-container:hover .hover-caret-visible::after {
    //visibility: visible;
    opacity: unset;
}

.hover-container:hover .hover-nodisplay {
    display: none;
}

.hover-container .hover-display {
    // display: none;
    opacity: 0.0625;
}

.hover-container:hover .hover-display {
    // display: unset;
    opacity: unset;
}

.hover-container:hover .hover-opacity-50 {
    opacity: 0.5 !important;
}

.hover-container:hover .hover-opacity-25 {
    opacity: 0.25 !important;
}

.hover-container:hover .hover-opacity-125 {
    opacity: 0.125 !important;
}

.form-control {
    user-select: text;
}

.select-text {
    user-select: text;
}

.bg-white,
a.bg-white:hover,
a.bg-light:focus,
button.bg-white:hover,
button.bg-white:focus {
    background-color: #fff !important;
}

input[type=date]::-webkit-inner-spin-button,
input[type=month]::-webkit-inner-spin-button,
input[type=number]::-webkit-inner-spin-button {
    display: none;
}

input[type=month][value='']::-webkit-datetime-edit-month-field,
input[type=month][value='']::-webkit-datetime-edit-year-field,
input[type=month][value='']::-webkit-datetime-edit-text,
input[type=date][value='']::-webkit-datetime-edit-day-field,
input[type=date][value='']::-webkit-datetime-edit-month-field,
input[type=date][value='']::-webkit-datetime-edit-year-field,
input[type=date][value='']::-webkit-datetime-edit-text {
    color: transparent;
}

input[type="date"]::-webkit-calendar-picker-indicator {
    margin-left: -2.5rem;
}

input[type=search]::-webkit-search-cancel-button {
    -webkit-appearance: none;
    height: 14px;
    width: 14px;
    display: block;
    background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAB4AAAAeCAYAAAA7MK6iAAAAn0lEQVR42u3UMQrDMBBEUZ9WfQqDmm22EaTyjRMHAlM5K+Y7lb0wnUZPIKHlnutOa+25Z4D++MRBX98MD1V/trSppLKHqj9TTBWKcoUqffbUcbBBEhTjBOV4ja4l4OIAZThEOV6jHO8ARXD+gPPvKMABinGOrnu6gTNUawrcQKNCAQ7QeTxORzle3+sDfjJpPCqhJh7GixZq4rHcc9l5A9qZ+WeBhgEuAAAAAElFTkSuQmCC);
    background-repeat: no-repeat;
    background-size: 14px;
}

.card-columns {
    column-count: unset;
}

.cols-1 {
    column-count: 1
}

.cols-2 {
    column-count: 2
}

.columns-2 {
    columns: 2
}

.cols-3 {
    column-count: 3
}

.columns-3 {
    columns: 2
}

.opacity-0625 {
    opacity: 0.0625
}

.opacity-125 {
    opacity: 0.125
}

.opacity-15 {
    opacity: 0.15
}

.opacity-25 {
    opacity: 0.25
}

.opacity-50 {
    opacity: 0.5
}

.opacity-75 {
    opacity: 0.75
}

.opacity-100 {
    opacity: 1
}

.hover-opacity-50:hover {
    opacity: 0.5
}

.opacity-reset {
    opacity: unset !important;
}

.display-inline {
    display: inline;
}

.lh-1 {
    line-height: 1;
}

.custom-control-nolabel {
    padding-left: 0;
}

.custom-control-nolabel > label::before {
    left: 0;
}

.custom-control-nolabel > label::after {
    left: 0;
}

.list-group-flush .list-group-item:last-child {
    border-bottom-width: 0;
}

.list-group-unstyled .list-group-item {
    border-bottom-width: 0;
}

.list-inline-comma li.list-inline-item {
    margin-right: 0px;
}

.list-inline-comma li.list-inline-item + li:before {
    content: ", ";
}

.stroke-light {
    stroke: #e9ecef;
}

.stroke-primary {
    stroke: #2780e3;
}

.stroke-muted {
    stroke: #868e96 !important;
}

.bt-0 {
    border-top-width: 0px !important;
}

.bb-0 {
    border-bottom-width: 0px !important;
}

.b-0 {
    border-width: 0px !important;
}

.br-1l {
    border-right: 1px solid #f8f9fa !important;
}

.mt-neg-3 {
    margin-top: -1rem;
}

.color-placeholder {
    color: #868e96;
}

.clamp {
    margin-bottom: 1rem;
}

.clamp-body {
    margin-bottom: 0;
    transition: max-height 0.35s ease;
}

.clamp-body > *:last-child {
    margin-bottom: 0;
}

.clamp-body > *:last-child > *:last-child {
    margin-bottom: 0;
}

.clamp-body > *:last-child > *:last-child > *:last-child {
    margin-bottom: 0;
}

.clamp-body > *:last-child > *:last-child > *:last-child > *:last-child {
    margin-bottom: 0;
}

.clamp-toggle {
    font-size: 80%;
}

.card {
    max-height: 999999px;
}

.card-selectable {
    padding-left: 2px;
}

.card-selectable.active {
    border-left: 3px #2780E3 solid;
    padding-left: 0px;
}

.fade-out {
    opacity: 0;
    max-height: 0px;
    margin-bottom: 0px !important;
    transition: opacity 0.35s ease, max-height 0.35s ease 0.17s, margin 0.35s ease 0.17s;
}

a.navbar-brand {
    white-space: normal;
    text-align: center;
    word-break: break-all;
}

.navbar-toggler:hover,
.navbar-toggler:focus,
.navbar-toggler:active {
    background-color: transparent !important;
}

html {
    font-size: 14px;
}

@media (min-width: 768px) {
    html {
        font-size: 16px;
    }
}

.box-shadow {
    box-shadow: 0 .25rem .75rem rgba(0, 0, 0, .05);
}

.toast-dark .toast-header {
    border-bottom-color: rgba(0,0,0,0.95) !important;
}

.toast-dark .toast-header,
.toast-dark .toast-body {
    color: #fff;
    background-color: #373a3c !important;
}

.card-buttons-right {
    border-top: 1px solid #e9ecef;
    display: flex;
    justify-content: flex-end;
}

.tooltip-inner {
    max-width: 300px;
}

.alert > p:last-child,
.markdown > p:last-child {
    margin-bottom: 0px;
}

.markdown th,
.markdown td {
    padding: 0.5rem;
}

.markdown td {
    border-top: 1px solid rgba(0, 0, 0, 0.125);
}

.markdown td,
.markdown th {
    border-right: 1px solid rgba(0, 0, 0, 0.125);
}

.markdown td:last-child,
.markdown th:last-child {
    border-right: 0px;
}

.markdown ul,
.markdown ol {
    padding-left: 0px;
    list-style-position: inside;
}

.progress-bar-flush {
    margin: 0px -15px;
}

.progress-bar-thin {
    height: 3px;
}

.card-header-right {
    float: right;
    line-height: 1;
    border: 0px;
}

.btn-link:hover,
.btn-link:focus,
.btn-link:active {
    text-decoration: none
}

.w-20 {
    width: 20%;
}

.w-80 {
    width: 80%;
}

.w-90 {
    width: 90%;
}

.w-7e {
    width: 7em !important;
}

.w-max-75 {
    max-width: 75%;
}

.w-max-90 {
    max-width: 90%;
}

.h-min-3 {
    min-height: 3rem;
}

.h-min-6 {
    min-height: 6rem;
}

.fa-xxs {
    font-size: 0.5em;
}

.nav-pills > .nav-item > .nav-link {
    padding: 0.375rem 0.75rem;
    border: 1px solid transparent;
}

.nav-pills > .nav-item > .nav-link.active {
    padding: 0.375rem 0.75rem;
    border: 1px solid #2780E3;
}

.nav > .nav-item > .nav-link {
    border-bottom: 3px solid transparent;
}

.nav > .nav-item > .nav-link.active {
    border-bottom: 3px solid #2780E3;
}

.nav.flex-column > .nav-item > .nav-link {
    border-left: 3px solid transparent;
    border-bottom: unset;
}

.nav.flex-column > .nav-item > .nav-link.active {
    border-left: 3px solid #2780E3;
}

.custom-switch-sm .custom-control-label::before {
    top: 0.25rem;
    left: -1.5rem;
    width: 1.25rem;
    height: 0.8rem;
    pointer-events: all;
    border-radius: 0.4rem;
}

.custom-switch-sm .custom-control-label::after {
    top: calc(0.25rem + 2px);
    left: calc(-1.5rem + 2px);
    width: calc(0.8rem - 4px);
    height: calc(0.8rem - 4px);
    border-radius: 0.4rem;
}

.custom-switch-sm .custom-control-input:checked ~ .custom-control-label::after {
    transform: translateX(0.5rem);
}

.strong {
    font-weight: 400;
}

.bubble-handle-bottom-right::before {
    content: "";
    display: inline-block;
    position: absolute;
    right: -9px;
    bottom: 7px;
    height: 0px;
    width: 0px;
    border-top: 8px solid transparent;
    border-bottom: 8px solid transparent;
    border-left: 8px solid rgba(0, 0, 0, 0.125);
}

.bubble-handle-bottom-right::after {
    content: "";
    display: inline-block;
    position: absolute;
    right: -8px;
    bottom: 7px;
    height: 0px;
    width: 0px;
    border-top: 8px solid transparent;
    border-bottom: 8px solid transparent;
    border-left: 8px solid white;
}

.bubble-handle-bottom-left::before {
    content: "";
    display: inline-block;
    position: absolute;
    left: -9px;
    bottom: 7px;
    height: 0px;
    width: 0px;
    border-top: 8px solid transparent;
    border-bottom: 8px solid transparent;
    border-right: 8px solid rgba(0, 0, 0, 0.125);
}

.bubble-handle-bottom-left::after {
    content: "";
    display: inline-block;
    position: absolute;
    left: -8px;
    bottom: 7px;
    height: 0px;
    width: 0px;
    border-top: 8px solid transparent;
    border-bottom: 8px solid transparent;
    border-right: 8px solid white;
}

.scrollx-sm,
.scrollx-0 {
    overflow-x: scroll;
}

.scrollx-sm::-webkit-scrollbar {
    height: 2px;
}

.scrollx-0::-webkit-scrollbar {
    display: none;
}

.scrollx-sm::-webkit-scrollbar-thumb,
.scrollx-0::-webkit-scrollbar-thumb {
    background-color: rgba(0, 0, 0, 0.125);
}

.cell {
    padding: 0.5rem;
}

.p-1px {
    padding: 1px;
}

.b-1 {
    border: 1px solid rgba(0, 0, 0, 0.125) !important;
}

.b-1i {
    border: 1px solid #e9ecef !important;
}

.bt-1 {
    border-top: 1px solid rgba(0, 0, 0, 0.125) !important;
}

.bl-1 {
    border-left: 1px solid rgba(0, 0, 0, 0.125) !important;
}

.br-1 {
    border-right: 1px solid rgba(0, 0, 0, 0.125) !important;
}

.bih-1 ~ .bih-1 {
    border-top: 1px solid rgba(0, 0, 0, 0.125) !important;
}

.bb-1 {
    border-bottom: 1px solid rgba(0, 0, 0, 0.125) !important;
}

.bl-1-white {
    border-left: 1px solid white !important;
}

.drag .drag-border {
    border: 1px dashed rgba(0, 0, 0, 0.125) !important;
}

mark, .mark {
    padding: unset;
}

.dropdown > button.btn-primary + button.btn-primary,
.dropdown > button.btn-primary + button.btn-primary:hover,
.dropdown > button.btn-primary + button.btn-primary:active {
    border-left: 1px solid white !important;
}

.dropdown > button.btn-link + button.btn-link,
.dropdown > button.btn-link + button.btn-link:hover,
.dropdown > button.btn-link + button.btn-link:active {
    border-left: 1px solid #2780E3 !important;
}

.dropdown > button.btn-label {
    padding: 0.375rem 0rem 0.375rem 0.75rem;
}

.text-highlight-diff strong {
    background-color: #d9f0d1;
    font-weight: normal;
}

.text-highlight-diff del {
    background-color: #ffccd7;
}


.fill-success {
    fill: #3FB618;
}

.fill-warning {
    fill: #FF7518;
}

.fill-danger {
    fill: #FF0039;
}

.fill-primary {
    fill: #2780E3;
}

.fill-secondary {
    fill: #373a3c;
}

.fill-dark {
    fill: #373a3c;
}

.fill-light {
    fill: #f8f9fa;
}

.carousel-indicators-dark li {
    background-color: #373a3c;
}

.react-datepicker-popper {
    z-index: 1000;
}

.react-datepicker-wrapper {
    width: 100%;
}

.react-datepicker__day {
    margin: 0;
    background-color: transparent !important;
    border-radius: 0 !important;
    border-bottom: 2px solid transparent;
}

.react-datepicker__day-name {
    margin: 0;
}

.react-datepicker__month-wrapper {
    display: flex;
}

.react-datepicker__month .react-datepicker__month-text {
    margin: 0;
}

.react-datepicker__close-icon::after {
    background-color: unset;
    color: unset;
    font-size: 1.40625rem;
    font-weight: 700;
    line-height: 1;
    opacity: 0.5;
    padding: 0.375rem 0.75rem;
}

.hide-outside-month .react-datepicker__day--outside-month {
    visibility: hidden;
}

.react-datepicker__day--selected,
.react-datepicker__day--in-selecting-range,
.react-datepicker__day--in-range,
.react-datepicker__month-text--selected,
.react-datepicker__month-text--in-selecting-range,
.react-datepicker__month-text--in-range,
.react-datepicker__quarter-text--selected,
.react-datepicker__quarter-text--in-selecting-range,
.react-datepicker__quarter-text--in-range,
.react-datepicker__day--keyboard-selected {
    background-color: unset;
    color: unset;
    border-bottom: 2px solid #2780E3;
}

@media screen and (min-width: 576px) {
    .cols-sm-1 {
        column-count: 1;
    }

    .cols-sm-2 {
        column-count: 2;
    }

    .cols-sm-3 {
        column-count: 3;
    }

    .w-sm-75 {
        width: 75% !important;
    }

    .w-sm-auto {
        width: auto !important;
    }

    .my-sm-0 {
        margin-top: 0 !important;
        margin-bottom: 0 !important;
    }

    .d-sm-inline {
        display: inline !important;
    }

    .d-sm-list-item {
        display: list-item !important;
    }

    .d-sm-flex {
        display: flex !important;
    }

    .flex-sm-column {
        flex-direction: column !important;
    }

    .flex-sm-row {
        flex-direction: row !important;
    }
}

@media screen and (min-width: 768px) {
    .cols-md-1 {
        column-count: 1;
    }

    .cols-md-2 {
        column-count: 2;
    }

    .cols-md-3 {
        column-count: 3;
    }

    .w-md-50 {
        width: 50% !important;
    }

    .w-md-100 {
        width: 100% !important;
    }

    .d-md-flex {
        display: flex;
    }

    .flex-md-column {
        flex-direction: column !important;
    }

    .flex-md-row {
        flex-direction: row !important;
    }

    .nav.flex-md-column > .nav-item > .nav-link {
        border-left: 3px solid transparent;
        border-bottom: unset;
    }

    .nav.flex-md-column > .nav-item > .nav-link.active {
        border-left: 3px solid #2780E3;
    }
}

@media screen and (min-width: 992px) {
    .cols-lg-1 {
        column-count: 1;
    }

    .cols-lg-2 {
        column-count: 2;
    }

    .cols-lg-3 {
        column-count: 3;
    }

    .w-lg-100 {
        width: 100% !important;
    }

    .hover-container .hover-visible {
        visibility: hidden;
    }

    .hover-container:hover .hover-visible {
        visibility: visible;
    }

    .hover-container .hover-hidden {
        visibility: visible;
    }

    .hover-container:hover .hover-hidden {
        visibility: hidden;
    }

    .hover-container .hover-caret-visible::after {
        visibility: hidden;
    }

    .hover-container:hover .hover-caret-visible::after {
        visibility: visible;
    }

    .hover-container .hover-display {
        display: none;
    }

    .hover-container:hover .hover-display {
        display: unset;
    }
}

@media screen and (min-width: 1200px) {
    .cols-xl-1 {
        column-count: 1;
    }

    .cols-xl-2 {
        column-count: 2;
    }

    .cols-xl-3 {
        column-count: 3;
    }
}

@media print {
    .cols-1, .cols-2, .cols-3 {
        column-count: 1
    }

    blockquote {
        border: 0;
        padding-left: 3em;
    }
}
